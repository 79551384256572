// React
import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "ui/components";
import { useTheme } from "theme";
import { useTranslate } from "localization/hooks";
import { classnames } from "ui/classnames";
import { Feed } from "ui/list";
// Components
import Header from "./components/Header";
import Search from "./components/Search";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      width: 350,
      minHeight: 200,
    },
  },
  content: {
    padding: 0,
    height: 400,
  },
  item: {
    paddingLeft: theme.spacing(3),
    minHeight: 54,
  },
  inset: {
    paddingLeft: theme.spacing(5),
  },
  flex: {
    flex: 1,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const EntityDialog = forwardRef(
  (
    {
      title,
      options,
      buttons,
      entities,
      loading,
      loadingMore,
      hasMore,
      error,
      search,
      emptyTitle,
      emptySubtitle,
      mapper,
      onSearch,
      onSelection,
      onLoadMore,
      onRefresh,
      onOpen,
      onClose,
    },
    ref
  ) => {
    // Framework
    const { t } = useTranslate();
    // Styles
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    // State
    const [open, setOpen] = useState(false);
    // Ref
    useImperativeHandle(ref, () => ({
      open() {
        setOpen(true);
        onOpen && onOpen();
      },
      close() {
        setOpen(false);
        onClose && onClose();
      },
    }));
    // Callbacks
    const handleLoadMore = useCallback(() => {
      onLoadMore();
    }, [onLoadMore]);
    const handleRefresh = useCallback(() => {
      onRefresh();
    }, [onRefresh]);
    const handleSelection = useCallback(
      (entity) => {
        setOpen(false);
        onSelection && onSelection(entity);
        onClose && onClose();
      },
      [onSelection, onClose]
    );
    const handleButtonSelection = useCallback(
      (onSelection) => {
        setOpen(false);
        onClose && onClose();
        onSelection && onSelection();
      },
      [onClose]
    );
    const handleClose = useCallback(
      (event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setOpen(false);
        onClose && onClose();
      },
      [onClose]
    );
    // Render
    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Header title={title} options={options} onClose={handleClose} />
        {search && <Search autoFocus onChange={onSearch} />}
        <DialogContent className={classes.content}>
          <Feed
            loading={loading}
            loadingMore={loadingMore}
            hasMore={hasMore}
            error={error}
            onLoadMore={handleLoadMore}
            onRefresh={handleRefresh}
            emptyTitle={emptyTitle}
            emptySubtitle={emptySubtitle}
            items={
              isEmpty(entities) ? null : (
                <List>
                  {map(entities, (entity, index) => {
                    const {
                      avatar,
                      primary,
                      secondary,
                      inset,
                      disabled,
                    } = mapper ? mapper(entity) : entity;
                    return (
                      <ListItem
                        button
                        key={index}
                        disabled={disabled}
                        onClick={() => handleSelection(entity)}
                        className={classes.item}
                      >
                        {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
                        <ListItemText
                          primary={primary}
                          secondary={secondary}
                          classes={{
                            secondary: classnames(inset && classes.inset),
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )
            }
          />
        </DialogContent>
        <DialogActions>
          {map(buttons, ({ title, onSelection }, index) => (
            <Button
              key={index}
              onClick={() => handleButtonSelection(onSelection)}
              size="small"
              color="primary"
            >
              {title}
            </Button>
          ))}
          <Box color="action.active">
            <Button onClick={handleClose} size="small" color="inherit">
              {t("core:ui.dialog.entity.action.close")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
);

EntityDialog.propTypes = {
  title: PropTypes.string.isRequired,
  mapper: PropTypes.func,
  entities: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  hasMore: PropTypes.bool,
  error: PropTypes.any,
  search: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptySubtitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["item", "divider"]),
      title: PropTypes.string,
      icon: PropTypes.element,
      onSelection: PropTypes.func,
    })
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onSelection: PropTypes.func.isRequired,
    })
  ),
  onSearch: PropTypes.func,
  onSelection: PropTypes.func,
  onLoadMore: PropTypes.func,
  onRefresh: PropTypes.func,
  onClose: PropTypes.func,
};

export default EntityDialog;
