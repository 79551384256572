1; // React
import React, {
  Children,
  forwardRef,
  useState,
  useCallback,
  cloneElement,
  isValidElement,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isNumber } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import {
  alpha,
  makeStyles,
  useMediaQuery,
  Spinner,
  Box,
  Card,
  Fade,
} from "ui/components";
import { useTheme } from "theme";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "100%",
    boxShadow: theme.shadows[0],
    borderRadius: theme.radius.large,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    transform: "translateZ(0)",
  },
  transparent: {
    background: "transparent",
    border: "none",
    borderRadius: theme.radius.none,
    boxShadow: theme.shadows[0],
  },
  backgroundImage: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  default: ({ bgcolor }) => ({
    backgroundColor: bgcolor ?? theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
  }),
  flat: ({ bgcolor }) => ({
    backgroundColor: bgcolor ?? theme.palette.background.underlying,
    boxShadow: theme.shadows[0],
  }),
  glass: ({ bgcolor }) => ({
    backgroundColor: bgcolor ?? alpha(theme.palette.common.white, 0.9),
    backdropFilter: "blur(8px)",
    boxShadow: theme.shadows[0],
  }),
  border: ({ bgcolor, borderColor }) => ({
    backgroundColor: bgcolor ?? theme.palette.background.underlying,
    boxShadow: theme.shadows[0],
    border: `1px solid ${borderColor ?? theme.palette.divider}`,
  }),
  selected: ({ bgcolor, borderColor }) => ({
    backgroundColor: bgcolor ?? alpha(theme.palette.primary.main, 0.07),
    boxShadow: theme.shadows[0],
    border: `2px solid ${borderColor ?? theme.palette.primary.main}`,
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Tile = forwardRef(
  (
    {
      context = "tile",
      value,
      display = "default",
      loading = false,
      fitContent,
      overflow,
      maxWidth,
      maxHeight,
      minWidth = "auto",
      minHeight,
      bgcolor,
      borderColor,
      backgroundImage,
      className,
      spinnerComponent,
      spinnerProps,
      children,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles({ bgcolor, borderColor });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up("sm"));
    // State
    const [hover, setHover] = useState("false");
    // Handlers
    const handleMouseOver = useCallback(() => {
      setHover("true");
    }, []);
    const handleMouseLeave = useCallback(() => {
      setHover("false");
    }, []);
    // Render
    return (
      <Section context={context} value={value}>
        <Box
          ref={ref}
          display="flex"
          margin="auto"
          width="100%"
          minWidth={fullScreen ? minWidth : "auto"}
          maxWidth={maxWidth ? maxWidth : "auto"}
          height={fitContent ? "fit-content" : "100%"}
          minHeight={
            minHeight ? (isNumber(minHeight) ? minHeight : 350) : "auto"
          }
          maxHeight={maxHeight}
          className={classnames(className)}
        >
          <Card
            className={classnames(classes.card, {
              [classes.default]: display === "default",
              [classes.transparent]: display === "transparent",
              [classes.flat]: display === "flat",
              [classes.border]: display === "border",
              [classes.glass]: display === "glass",
              [classes.selected]: display === "selected",
              [classes.backgroundImage]: !!backgroundImage,
            })}
            overflow={overflow ? "true" : "auto"}
            style={{
              ...(backgroundImage && {
                backgroundImage: `url(${backgroundImage})`,
              }),
            }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            <Fade in={!loading} timeout={0}>
              <Box height="100%">
                <Box display="flex" flexDirection="column" height="100%">
                  {Children.map(children, (child) =>
                    isValidElement(child)
                      ? cloneElement(child, { hover })
                      : null
                  )}
                </Box>
              </Box>
            </Fade>
            {loading && (
              <div className={classes.spinner}>
                {spinnerComponent ?? (
                  <Spinner size="small" position="center" {...spinnerProps} />
                )}
              </div>
            )}
          </Card>
        </Box>
      </Section>
    );
  }
);

Tile.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  display: PropTypes.oneOf([
    "default",
    "border",
    "flat",
    "transparent",
    "glass",
    "selected",
  ]),
  loading: PropTypes.bool,
  fitContent: PropTypes.bool,
  overflow: PropTypes.bool,
  minWidth: PropTypes.number,
  minHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  bgcolor: PropTypes.string,
  backgroundImage: PropTypes.string,
  spinnerProps: PropTypes.object,
  spinnerComponent: PropTypes.node,
};

export default Tile;
