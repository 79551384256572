// React
import React from "react";
// Framework
import { forwardType, External, Externals } from "@mefisto/web";
// Components
import AppFeatures from "./images/app-features.png";
import AppIcon from "./images/app-icon.png";
import AppScreen1 from "./images/app-screen-1.png";
import AppScreen2 from "./images/app-screen-2.png";
import AppTitle from "./images/app-title.png";
import BenefitsSlp from "./images/benefits-slp.jpg";
import BenefitsParents from "./images/benefits-parents.jpg";
import Connect from "./images/connect.png";
import CreateAccount from "./images/create-account.png";
import AboutPhoto from "./images/jamilet-figueroa.jpg";
import LandingBackground from "./images/landing-background.jpg";
import LearnMoreSlp0 from "./images/learn-more-slp-image-0.jpg";
import LearnMoreSlp1 from "./images/learn-more-slp-image-1.jpg";
import LearnMoreParent0 from "./images/learn-more-parent-image-0.jpg";
import LearnMoreParent1 from "./images/learn-more-parent-image-1.jpg";
import GameBackgroundArticulation from "./images/game-background-articulation.jpg";
import GameBackgroundCategorization from "./images/game-background-categorization.jpg";
import GameBackgroundConventions from "./images/game-background-convetions.jpg";
import GameBackgroundFunctions from "./images/game-background-functions.jpg";
import GameBackgroundHome from "./images/game-background-home.jpg";
import GameBackgroundIdentify from "./images/game-background-identify.jpg";
import GameBackgroundIdentifySentence from "./images/game-background-identify-sentence.jpg";
import GameBackgroundInterfaces from "./images/game-background-interfaces.jpg";
import GameBackgroundMemory from "./images/game-background-memory.jpg";
import GameBackgroundPragmatics from "./images/game-background-pragmatics.jpg";
import GameBackgroundQuestions from "./images/game-background-questions.jpg";
import GameBackgroundSemantics from "./images/game-background-semantics.jpg";
import GameBackgroundSpaceConcepts from "./images/game-background-space-concepts.jpg";
import GameBackgroundSynonyms from "./images/game-background-synonyms.jpg";
import GameBackgroundSyntax from "./images/game-background-syntax.jpg";
import GameBackgroundVocabulary from "./images/game-background-vocabulary.jpg";
import GameCreditGiphy from "./images/game-credit-giphy.png";
import GameCreditUnsplash from "./images/game-credit-unsplash.png";
import GameIconFailure from "./images/game-icon-failure.png";
import GameIconGroup from "./images/game-icon-group.png";
import GameIconHear from "./images/game-icon-hear.png";
import GameIconHelp from "./images/game-icon-help.png";
import GameIconPersona from "./images/game-icon-persona.png";
import GameIconPlay from "./images/game-icon-play.png";
import GameIconRecord from "./images/game-icon-record.png";
import GameIconRetry from "./images/game-icon-retry.png";
import GameIconSpeech from "./images/game-icon-speech.png";
import GameIconStop from "./images/game-icon-stop.png";
import GameIconSuccess from "./images/game-icon-success.png";
import GameIconTips from "./images/game-icon-tips.png";
import GameStars from "./images/game-stars.gif";
import Parents from "./images/parents.png";
import SchoolBackground from "./images/school-background.jpg";
import SubscriptionBackground from "./images/subscription-background.jpg";
import SLP from "./images/slp.png";
import SplashScreen from "./images/splash-screen.jpg";
import Tablet from "./images/tablet.png";
import Unlock from "./images/unlock.png";
import WebAppMarket from "./images/webapp-market.png";
import GameSoundFailure from "./sounds/game-sound-failure.mp3";
import GameSoundIntro from "./sounds/game-sound-intro.mp3";
import GameSoundSuccess from "./sounds/game-sound-success.mp3";

// prettier-ignore
export default forwardType(Externals, () => (
  <Externals>
    {/* Images */}
    <External name="APP_FEATURES" path={AppFeatures} />
    <External name="APP_ICON" path={AppIcon} />
    <External name="APP_SCREEN_1" path={AppScreen1} />
    <External name="APP_SCREEN_2" path={AppScreen2} />
    <External name="APP_TITLE" path={AppTitle} />
    <External name="ABOUT_PHOTO" path={AboutPhoto} />
    <External name="BENEFITS_SLP" path={BenefitsSlp} />
    <External name="BENEFITS_PARENTS" path={BenefitsParents} />
    <External name="CONNECT" path={Connect} />
    <External name="CREATE_ACCOUNT" path={CreateAccount} />
    <External name="GAME_BACKGROUND_ARTICULATION" path={GameBackgroundArticulation} />
    <External name="GAME_BACKGROUND_CATEGORIZATION" path={GameBackgroundCategorization} />
    <External name="GAME_BACKGROUND_CONVENTIONS" path={GameBackgroundConventions} />
    <External name="GAME_BACKGROUND_FUNCTIONS" path={GameBackgroundFunctions} />
    <External name="GAME_BACKGROUND_HOME" path={GameBackgroundHome} />
    <External name="GAME_BACKGROUND_IDENTIFY" path={GameBackgroundIdentify} />
    <External name="GAME_BACKGROUND_IDENTIFY_SENTENCE" path={GameBackgroundIdentifySentence} />
    <External name="GAME_BACKGROUND_INTERFACES" path={GameBackgroundInterfaces} />
    <External name="GAME_BACKGROUND_MEMORY" path={GameBackgroundMemory} />
    <External name="GAME_BACKGROUND_PRAGMATICS" path={GameBackgroundPragmatics} />
    <External name="GAME_BACKGROUND_QUESTIONS" path={GameBackgroundQuestions} />
    <External name="GAME_BACKGROUND_SEMANTICS" path={GameBackgroundSemantics} />
    <External name="GAME_BACKGROUND_SPACE_CONCEPTS" path={GameBackgroundSpaceConcepts} />
    <External name="GAME_BACKGROUND_SYNONYMS" path={GameBackgroundSynonyms} />
    <External name="GAME_BACKGROUND_SYNTAX" path={GameBackgroundSyntax} />
    <External name="GAME_BACKGROUND_VOCABULARY" path={GameBackgroundVocabulary} />
    <External name="GAME_CREDIT_GIPHY" path={GameCreditGiphy} />
    <External name="GAME_CREDIT_UNSPLASH" path={GameCreditUnsplash} />
    <External name="GAME_ICON_FAILURE" path={GameIconFailure} />
    <External name="GAME_ICON_GROUP" path={GameIconGroup} />
    <External name="GAME_ICON_HEAR" path={GameIconHear} />
    <External name="GAME_ICON_HELP" path={GameIconHelp} />
    <External name="GAME_ICON_PERSONA" path={GameIconPersona} />
    <External name="GAME_ICON_PLAY" path={GameIconPlay} />
    <External name="GAME_ICON_RECORD" path={GameIconRecord} />
    <External name="GAME_ICON_RETRY" path={GameIconRetry} />
    <External name="GAME_ICON_SPEECH" path={GameIconSpeech} />
    <External name="GAME_ICON_STOP" path={GameIconStop} />
    <External name="GAME_ICON_SUCCESS" path={GameIconSuccess} />
    <External name="GAME_ICON_TIPS" path={GameIconTips} />
    <External name="GAME_STARS" path={GameStars} />
    <External name="LANDING_BACKGROUND" path={LandingBackground} />
    <External name="LEARN_MORE_SLP_0" path={LearnMoreSlp0} />
    <External name="LEARN_MORE_SLP_1" path={LearnMoreSlp1} />
    <External name="LEARN_MORE_PARENT_0" path={LearnMoreParent0} />
    <External name="LEARN_MORE_PARENT_1" path={LearnMoreParent1} />
    <External name="PARENTS" path={Parents} />
    <External name="SCHOOL_BACKGROUND" path={SchoolBackground} />
    <External name="SUBSCRIPTION_BACKGROUND" path={SubscriptionBackground} />
    <External name="SLP" path={SLP} />
    <External name="SPLASH_SCREEN" path={SplashScreen} />
    <External name="TABLET" path={Tablet} />
    <External name="UNLOCK" path={Unlock} />
    <External name="WEB_APP_MARKET" path={WebAppMarket} />
    {/* Sounds */}
    <External name="GAME_SOUND_FAILURE" path={GameSoundFailure} />
    <External name="GAME_SOUND_INTRO" path={GameSoundIntro} />
    <External name="GAME_SOUND_SUCCESS" path={GameSoundSuccess} />
  </Externals>
));
